import { createRouter, createWebHashHistory } from "vue-router"; // createWebHistory
const routes = [{
        path: '/',
        redirect: '/portal'
      },
    {
        path: '/portal',
        name: 'home',
        component: () =>
            import ('@/views/home/home.vue')
    },
   
    {
        path: '/index',
        name: 'index',
        component: () =>
            import ('@/views/home/index.vue')
    },
    {
        path: '/search',
        name: 'searchs',
        component: () =>
            import ('@/views/home/search.vue')
    },
    {
        path: '/portal/how',
        name: 'how',
        component: () =>
            import ('@/views/learn/how.vue')
    },
    {
        path: '/portal/what',
        name: 'what1',
        component: () =>
            import ('@/views/learn/what.vue')
    },
    {
        path: '/learn-to-invest',
        name: 'learn-to-invest',
        component: () =>
            import ('@/views/learn/index.vue')
    },
    {
        path: '/portal/free',
        name: 'free',
        component: () =>
            import ('@/views/learn/free.vue')
    },
    {
        path: '/portal/invest',
        name: 'invest',
        component: () =>
            import ('@/views/learn/invest.vue')
    },
    {
        path: '/terms-conditions',
        name: 'conditions',
        component: () =>
            import ('@/views/conditions/index.vue')
    },
    {
        path: '/cookie-policy',
        name: 'cookie',
        component: () =>
            import ('@/views/conditions/cookie.vue')
    },
    {
        path: '/privacy-policy',
        name: 'privacy',
        component: () =>
            import ('@/views/conditions/privacy.vue')
    },
    {
        path: '/ideas-insights',
        name: '',
        component: () =>
            import ('@/views/idea/index.vue')
    },
    {
        path: '/ideas-detail0',
        name: 'ideasDetail0',
        component: () =>
            import ('@/views/idea/detail0.vue')
    },
    {
        path: '/ideas-detail-1',
        name: 'ideasDetail-1',
        component: () =>
            import ('@/views/idea/detail-1.vue')
    },
    {
        path: '/ideas-detail-2',
        name: 'ideasDetail-2',
        component: () =>
            import ('@/views/idea/detail-2.vue')
    },
    {
        path: '/ideas-detail-3',
        name: 'ideasDetail-3',
        component: () =>
            import ('@/views/idea/detail-3.vue')
    },
    {
        path: '/ideas-detail-4',
        name: 'ideasDetail-4',
        component: () =>
            import ('@/views/idea/detail-4.vue')
    },
    {
        path: '/ideas-detail-5',
        name: 'ideasDetail-5',
        component: () =>
            import ('@/views/idea/detail-5.vue')
    },
    {
        path: '/ideas-detail-6',
        name: 'ideasDetail-6',
        component: () =>
            import ('@/views/idea/detail-6.vue')
    },
    // 
    {
        path: '/ideas-detail1',
        name: 'ideasDetail1',
        component: () =>
            import ('@/views/idea/detail1.vue')
    },
    // {
    //     path: '/ideas-detail1',
    //     name: 'ideasDetail1',
    //     component: () =>
    //         import ('@/views/idea/detail1.vue')
    // },
    {
        path: '/ideas-detail2',
        name: 'ideasDetail2',
        component: () =>
            import ('@/views/idea/detail2.vue')
    },
    {
        path: '/ideas-detail3',
        name: 'ideasDetail3',
        component: () =>
            import ('@/views/idea/detail13.vue')
    },
    {
        path: '/ideas-detail4',
        name: 'ideasDetail4',
        component: () =>
            import ('@/views/idea/detail4.vue')
    },
    {
        path: '/ideas-detail5',
        name: 'ideasDetail5',
        component: () =>
            import ('@/views/idea/detail5.vue')
    },
    {
        path: '/ideas-detail6',
        name: 'ideasDetail6',
        component: () =>
            import ('@/views/idea/detail6.vue')
    },
    {
        path: '/ideas-detail7',
        name: 'ideasDetail7',
        component: () =>
            import ('@/views/idea/detail7.vue')
    },
    {
        path: '/ideas-detail8',
        name: 'ideasDetail8',
        component: () =>
            import ('@/views/idea/detail8.vue')
    },
    {
        path: '/ideas-detail9',
        name: 'ideasDetail9',
        component: () =>
            import ('@/views/idea/detail9.vue')
    },
    {
        path: '/ideas-detail10',
        name: 'ideasDetail10',
        component: () =>
            import ('@/views/idea/detail10.vue')
    },
    {
        path: '/ideas-detail11',
        name: 'ideasDetail11',
        component: () =>
            import ('@/views/idea/detail11.vue')
    },
    {
        path: '/ideas-detail12',
        name: 'ideasDetail12',
        component: () =>
            import ('@/views/idea/detail12.vue')
    },
    {
        path: '/ideas-detail13',
        name: 'ideasDetail13',
        component: () =>
            import ('@/views/idea/detail13.vue')
    },
    {
        path: '/ideas-detail14',
        name: 'ideasDetail14',
        component: () =>
            import ('@/views/idea/detail14.vue')
    },
    // 
    {
        path: '/elite-funds',
        name: '',
        component: () =>
            import ('@/views/funds/index.vue')
    },
    
    
   
    {
        path: '/portal/what',
        name: 'what',
        component: () =>import ('@/views/about/whats.vue'),
    },
    {
        path: '/portal/about',
        name: 'about',
        component: () =>import ('@/views/about/about.vue'),
    },
    {
        path: '/portal/contact',
        name: 'contact',
        component: () =>import ('@/views/about/contact.vue'),
    },
    {
        path: '/portal/press',
        name: 'press',
        component: () =>import ('@/views/about/press.vue'),
    },
    {
        path: '/portal/meet',
        name: 'meet',
        component: () =>import ('@/views/about/meeting.vue'),
    },
    {
        path: '/login',
        name: '',
        component: () =>
            import ('@/views/login/index.vue')
    },
    {
        path: '/lostpassword',
        name: '',
        component: () =>
            import ('@/views/login/lostpassword.vue')
    },
    {
        path: '/register',
        name: '',
        component: () =>
            import ('@/views/register/index.vue')
    },
]
const router = createRouter({
    history: createWebHashHistory(),
    routes
})
export default router;