<template>
  <!-- <img alt="Vue logo" src="./assets/logo.png">
  <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  <router-view v-slot="slotProps">
    <component :is="slotProps.Component" v-if="!$route.meta.keepAlive"></component>
    <keep-alive>
      <component :is="slotProps.Component" v-if="$route.meta.keepAlive"></component>
    </keep-alive>
  </router-view>
</template>

<script>

export default {
  name: 'App',
  components: {
    //HelloWorld
  },
  computed: {
   key() {
       return this.$route.name !== undefined? this.$route.name + +new Date(): this.$route + +new Date()
   }
  },
  created() {
  
  }
}
</script>
<style>
 @import "~assets/css/header.css";
 @import "~assets/css/body.css";
</style>
<style lang="scss">

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
}

*{
  margin:0;
  padding:0;
}
html{
  // height:100vh;
  // overflow: hidden;
}
body{
  width:100%;
  height:100vh;
  // overflow-y: scroll;
}
</style>
